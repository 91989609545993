import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Snackbar, CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import { CreateFreightItemDialog } from "./dialogs/CreateFreightItemDialog";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR, SUPPORT_EMAIL } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedProposal,
  updateProjectKeepClean,
} from "../reducers";
import { GridToolbar } from "@mui/x-data-grid-pro";
import ThemedDataGridPro from "./ThemedDataGridPro";
import ThemedIconButton from "./ThemedIconButton";

function FreightItems({ value, index }) {
  const dispatchState = useDispatch();
  const { project_id } = useParams();
  const project = useSelector((state) => state.project);
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const [freightItems, setFreightItems] = useState([]);
  const [selectedFreightItems, setSelectedFreightItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [gridKey, setGridKey] = useState(false);
  const [editableFreightItem, setEditableFreightItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [freightsLoading, setFreightsLoading] = useState(false);
  const [isExistingProposal, setIsExistingProposal] = useState(false);

  const columns = [
    { field: "type", headerName: "Type", flex: 3 },
    { field: "description", headerName: "Description", flex: 6 },
    { field: "ship_from", headerName: "Ship From", flex: 5 },
    { field: "ship_to", headerName: "Ship To", flex: 5 },
    { field: "quantity", headerName: "Quantity", flex: 2 },
    { field: "size_unit", headerName: "Unit", flex: 2 },
    { field: "unit_price", headerName: "Unit Price", flex: 2 },
    { field: "total_price", headerName: "Total $", flex: 2 },
    {
      field: "edit",
      headerName: "",
      renderCell: (row) => (
        <ThemedIconButton
          onClick={() => {
            setEditableFreightItem(
              freightItems.filter(
                (freightItem) => freightItem.id === row.id,
              )[0],
            );
            setOpen(true);
          }}
        >
          <EditIcon />
        </ThemedIconButton>
      ),
      width: 65,
    },
  ];

  const handleDialogClose = () => {
    setSelectedFreightItems([]);
    setOpen(false);
    setEditableFreightItem(null);
    setGridKey(!gridKey); // Force re-render
  };

  const listProjectFreightItems = (isProposal) => {
    setFreightsLoading(true);
    decoratedFetch(
      `/list_project_freight_items?${isProposal ? "proposal_id" : "project_id"}=${isProposal ? selectedProposal.id : project_id}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setFreightsLoading(false);
        setFreightItems(data);
      })
      .catch((error) => {
        setFreightsLoading(false);
        console.error(`Error listing project freight items: ${error}`);
      });
  };

  const updateProjectFreightSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateProjectKeepClean({
        ...project,
        freight_subtotal: project?.freight_subtotal
          ? project.freight_subtotal + itemTotalDiff
          : itemTotalDiff,
      }),
    );
  };

  const updateProposalFreightSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateSelectedProposal({
        ...selectedProposal,
        freight_subtotal: selectedProposal?.freight_subtotal
          ? selectedProposal.freight_subtotal + itemTotalDiff
          : itemTotalDiff,
      }),
    );
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    const isProposal =
      selectedProposal &&
      Object.keys(selectedProposal).length !== 0 &&
      selectedProposal.id !== "new_proposal";
    listProjectFreightItems(isProposal);
    setIsExistingProposal(isProposal);
  }, [value, selectedProposal]);

  const handleDialogSubmit = (totalPrice, prevTotalPrice, isProposal) => {
    if (isProposal) {
      updateProposalFreightSubtotal(totalPrice - prevTotalPrice);
    } else {
      updateProjectFreightSubtotal(totalPrice - prevTotalPrice);
    }
    listProjectFreightItems(isProposal);
  };

  const handleRemoveFreightItems = () => {
    setLoading(true);
    decoratedFetch(`/delete_project_freight_items`, {
      method: "POST",
      body: JSON.stringify({
        project_id: project_id,
        proposal_id: isExistingProposal ? selectedProposal.id : "",
        freight_item_ids: selectedFreightItems,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        const selectedFreightTotalPrices = freightItems
          .filter((freightItem) =>
            selectedFreightItems.includes(freightItem.id),
          )
          .map((freightItem) => parseFloat(freightItem.total_price));
        setFreightItems(
          freightItems.filter(
            (freightItem) => !selectedFreightItems.includes(freightItem.id),
          ),
        );
        const positiveDiff = selectedFreightTotalPrices.reduce(
          (sum, totalPrice) => sum + totalPrice,
          0,
        );
        if (isExistingProposal) {
          updateProposalFreightSubtotal(-1 * positiveDiff);
        } else {
          updateProjectFreightSubtotal(-1 * positiveDiff);
        }
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `Freight item${selectedFreightItems.length > 1 ? "s" : ""} deleted`,
        );
        setSelectedFreightItems([]);
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error deleting freight: ${error}`);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `An error occurred when deleting freight item${selectedFreightItems.length > 1 ? "s" : ""}`,
        );
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <CreateFreightItemDialog
        freightItem={editableFreightItem}
        isOpen={open}
        handleClose={handleDialogClose}
        handleSubmit={handleDialogSubmit}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        proposalId={isExistingProposal ? selectedProposal.id : ""}
      />
      <div style={{ height: freightItems.length ? "auto" : "200px", width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="right"
          sx={{ marginBottom: "10px" }}
        >
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedFreightItems.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <ThemedIconButton
                aria-label="remove-freight-item"
                onClick={handleRemoveFreightItems}
              >
                <RemoveIcon />
              </ThemedIconButton>
            )}
          </Grid>
          <Grid item>
            <ThemedIconButton
              aria-label="add-freight-item"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
            </ThemedIconButton>
          </Grid>
        </Grid>
        <ThemedDataGridPro
          key={gridKey}
          slots={{ toolbar: GridToolbar }}
          rows={freightItems.map((item) => ({
            id: item.id,
            type: item?.freight_item_type,
            size: item?.freight_size,
            ship_to: item?.ship_to,
            ship_from: item?.ship_from,
            description: item?.description,
            size_unit: item?.size_unit.name,
            unit_price: `$${item?.unit_price ? item.unit_price : "0.00"}`,
            quantity: item?.quantity,
            total_price: `$${item?.total_price}`,
          }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[10]}
          onRowSelectionModelChange={(newSelectionModel) =>
            setSelectedFreightItems(newSelectionModel)
          }
          getRowId={(row) => row.id}
          selectionModel={selectedFreightItems}
          checkboxSelection
          localeText={{ noRowsLabel: "No freight items" }}
          loading={freightsLoading}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
              {
                outline: "none",
              },
          }}
        />
      </div>
    </>
  );
}

export default FreightItems;
