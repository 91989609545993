const initialState = {
  authenticated: false,
  employee: {},
  project: {},
  refreshFiles: false,
  refreshLogs: false,
  refreshProposalCustomers: false,
  refreshProposalLocations: false,
  proposals: [],
  selectedProposal: {},
  selectedProposalDetails: {},
  darkThemeEnabled: false,
};

export const initializeSelectedProposal = () => ({
  type: "initialize_selected_proposal",
});

export const initializeProject = (project) => ({
  type: "initialize_project",
  payload: project,
});

export const resetProject = () => ({
  type: "reset_project",
});

export const setDarkThemeEnabled = enabled => ({
  type: "set_dark_theme_enabled",
  payload: enabled,
});

export const updateProject = (project) => ({
  type: "update_project",
  payload: project,
});

export const updateProjectKeepClean = (project) => ({
  type: "update_project_keep_clean",
  payload: project,
});

export const markProjectClean = () => ({
  type: "mark_project_clean",
});

export const refreshFiles = (refresh) => ({
  type: "refresh_files",
  payload: refresh,
});

export const refreshLogs = (refresh) => ({
  type: "refresh_logs",
  payload: refresh,
});

export const selectProposal = (proposalId) => ({
  type: "select_proposal",
  payload: proposalId,
});

export const selectProposalAndRefreshProposals = newProposals => ({
  type: "select_proposal_and_refresh",
  payload: newProposals,
});

export const selectDuplicateProposalAndRefreshProposals = newProposal => ({
  type: "select_duplicate_proposal_and_refresh",
  payload: newProposal,
});

export const updateProposals = (proposals) => ({
  type: "update_proposals",
  payload: proposals,
});

export const updateSelectedProposal = (proposal) => ({
  type: "update_selected_proposal",
  payload: proposal,
});

export const updateSelectedProposalDetails = details => ({
  type: "update_selected_proposal_details",
  payload: details,
});

export const refreshProposalCustomers = (refresh) => ({
  type: "refresh_proposal_customers",
  payload: refresh,
});

export const refreshProposalLocations = (refresh) => ({
  type: "refresh_proposal_locations",
  payload: refresh,
});

export const updateAccount = (account) => ({
  type: "update_account",
  payload: account,
});

export const updateEmployee = (employee) => ({
  type: "update_employee",
  payload: employee,
});

export const partiallyUpdateEmployee = employee => ({
  type: "partially_update_employee",
  payload: employee,
});

export default function rootReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case "update_employee":
      newState.employee = action.payload;
      break;
    case "partially_update_employee":
      newState.employee = { ...state.employee, ...action.payload };
      break;
    case "mark_as_authenticated":
      newState.employee = action.employee;
      newState.authenticated = true;
      break;
    case "mark_as_unauthenticated":
      newState = initialState;
      break;
    case "initialize_project":
      newState.project = action.payload;
      newState.project.isDirty = false;
      break;
    case "reset_project":
      return {
        ...state,
        project: initialState.project,
      };
    case "set_dark_theme_enabled":
      return {
        ...state,
        darkThemeEnabled: action.payload,
      };
    case "mark_project_clean":
      state.project.isDirty = false;
      return state;
    case "update_project":
      newState.project = action.payload;
      newState.project.isDirty = true;
      break;
    case "update_project_keep_clean":
      newState.project = action.payload;
      newState.project.isDirty = false;
      break;
    case "refresh_files":
      newState.refreshFiles = action.payload;
      break;
    case "refresh_logs":
      newState.refreshLogs = action.payload;
      break;
    case "select_proposal":
      newState.selectedProposal = state.proposals.find(
        (proposal) => proposal.id === action.payload,
      );
      newState.refreshProposals = false;
      break;
    case "select_proposal_and_refresh":
      state.selectedProposal = action.payload.new_proposal;
      state.proposals.shift();
      state.proposals.unshift(action.payload.new_draft_proposal, action.payload.new_proposal);
      return state;
    case "select_duplicate_proposal_and_refresh":
      state.selectedProposal = action.payload;
      state.proposals.splice(1, 0, action.payload);
      return state;
    case "update_proposals":
      newState.proposals = action.payload;
      break;
    case "update_selected_proposal":
      newState.selectedProposal = { ...state.selectedProposal, ...action.payload };
      newState.proposals = state.proposals.map((proposal) =>
        proposal.id === newState.selectedProposal.id ? newState.selectedProposal : proposal,
      );
      break;
    case "initialize_selected_proposal":
      // if (!state.selectedProposalDetails || Object.keys(state.selectedProposalDetails).length === 0) {
        newState.selectedProposal = {
					id: '',
					name: '',
					customer: '',
					location: '',
					start_date: '',
					submitted_by: '',
					material_subtotal: 0,
					material_markup_rate: 0,
					material_sales_tax_rate: 0,
					labor_subtotal: 0,
					labor_markup_rate: 0,
					freight_subtotal: 0,
					p_and_p_bond: 0,
					proposal_total: 0,
					contract_price: '',
					completion_time_months: '',
					completion_time_surcharge: '',
					included_area: '',
					consultant_fee_rate: '',
					is_draft: ''
        };
        newState.selectedProposalDetails = {
          cmas_id: '',
          id: '',
          include_price_category_breakdown: false,
          include_sales_tax: false,
          is_itemized: false,
          included_area: '',
          proposal_template_id: '',
          inclusions: [],
          exclusions: [],
          notes: [],
        };
      // }
      break;
    case "update_selected_proposal_details":
      newState.selectedProposalDetails = { ...state.selectedProposalDetails, ...action.payload };
      break;
    case "refresh_proposal_customers":
      newState.refreshProposalCustomers = action.payload;
      break;
    case "refresh_proposal_locations":
      newState.refreshProposalLocations = action.payload;
      break;
    case "update_account":
      newState.project = state.project;
      newState.project.account = action.payload;
      break;
    default:
      console.log(`Unknown redux action type: ${action.type}`);
  }
  return { ...state, ...newState };
}
