import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Box,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from "@mui/material";
import { updateProject, updateSelectedProposal } from "../reducers";
import ThemedTextField from "./ThemedTextField";
import ThemedTypography from "./ThemedTypography";
import ThemedPaper from "./ThemedPaper";
import ThemedTable from "./ThemedTable";

const initialRows = [
  {
    id: 1,
    name: "FIRST 100M",
    contractPrice: 0,
    ratePerM: 11.75,
    totalPremium: 0,
  },
  {
    id: 2,
    name: "NEXT 400M",
    contractPrice: 0,
    ratePerM: 9.00,
    totalPremium: 0,
  },
  {
    id: 3,
    name: "NEXT 2000M",
    contractPrice: 0,
    ratePerM: 7.50,
    totalPremium: 0,
  },
  {
    id: 4,
    name: "NEXT 2500M",
    contractPrice: 0,
    ratePerM: 6.25,
    totalPremium: 0,
  },
];

const BondRater = () => {
  const [contractPrice, setContractPrice] = useState("");
  const [completionTimeSurcharge, setCompletionTimeSurcharge] = useState("");
  const [completionTimeMonths, setCompletionTimeMonths] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [subTotalPremium, setSubTotalPremium] = useState(0);
  const [rows, setRows] = useState(initialRows);
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();

  const valuesFromProject = () => ({
    contract_price: parseFloat(project?.contract_price || "0.00").toFixed(2),
    completion_time_months: project?.completion_time_months || 12,
    completion_time_surcharge: parseFloat(
      project?.completion_time_surcharge || "1",
    ).toFixed(2),
  });

  const valuesFromProposal = () => ({
    contract_price: parseFloat(
      selectedProposal?.contract_price || "0.00",
    ).toFixed(2),
    completion_time_months: selectedProposal?.completion_time_months || 12,
    completion_time_surcharge: parseFloat(
      selectedProposal?.completion_time_surcharge || "1",
    ).toFixed(2),
  });

  useEffect(() => {
    const projectPresent = project && Object.keys(project).length !== 0;
    const proposalPresent =
      selectedProposal &&
      Object.keys(selectedProposal).length !== 0 &&
      selectedProposal.id !== "new_proposal";

    if (projectPresent || proposalPresent) {
      let newValues = proposalPresent
        ? valuesFromProposal()
        : valuesFromProject();
      setCompletionTimeSurcharge(newValues.completion_time_surcharge);
      setContractPrice(newValues.contract_price);
      setCompletionTimeMonths(newValues.completion_time_months);
    }
  }, [project, selectedProposal]);

  const handleContractPriceChange = (e) => {
    setContractPrice(parseFloat(e.target.value) || 0);
  };

  const handleBlur = (event) => {
    if (selectedProposal?.id && selectedProposal.id === "new_proposal") {
      dispatch(
        updateProject({
          ...project,
          contract_price: parseFloat(contractPrice),
          completion_time_months: completionTimeMonths,
          completion_time_surcharge: parseFloat(completionTimeSurcharge),
          p_and_p_bond: parseFloat(totalPremium),
        }),
      );
    } else {
      dispatch(
        updateSelectedProposal({
          ...selectedProposal,
          contract_price: parseFloat(contractPrice),
          completion_time_months: completionTimeMonths,
          completion_time_surcharge: parseFloat(completionTimeSurcharge),
          p_and_p_bond: parseFloat(totalPremium),
        }),
      );
    }
  };

  const handleCompletionTimeSurchargeChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      setCompletionTimeSurcharge(value);
    }
  };

  useEffect(() => {
    setTotalPremium(parseFloat(completionTimeSurcharge || 1) * subTotalPremium);
  }, [completionTimeSurcharge, subTotalPremium]);

  useEffect(() => {
    let remainingPrice = contractPrice;
    const updatedRows = rows.map((row) => {
      let allocatedPrice = 0;

      switch (row.name) {
        case "FIRST 100M":
          allocatedPrice = Math.min(remainingPrice, 100000);
          break;
        case "NEXT 400M":
          allocatedPrice = Math.min(remainingPrice, 400000);
          break;
        case "NEXT 2000M":
          allocatedPrice = Math.min(remainingPrice, 2000000);
          break;
        case "NEXT 2500M":
          allocatedPrice = Math.min(remainingPrice, 2500000);
          break;
        case "OVER 10000M":
          allocatedPrice = remainingPrice;
          break;
        default:
          allocatedPrice = 0;
      }

      remainingPrice -= allocatedPrice;

      const premium = Math.ceil((allocatedPrice / 1000) * row.ratePerM);

      return {
        ...row,
        contractPrice: allocatedPrice,
        totalPremium: premium || 0,
      };
    });

    setRows(updatedRows);

    const total = updatedRows.reduce((sum, row) => sum + row.totalPremium, 0);
    setSubTotalPremium(total || 0);
  }, [contractPrice]);

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            label="Contract Price"
            variant="outlined"
            fullWidth
            value={contractPrice}
            onChange={handleContractPriceChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            placeholder="0"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ marginLeft: "4px" }}
                >
                  <span style={{ marginTop: "-2px" }}>$</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              pattern: "[0-9]*[.]?[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            label="Completion"
            variant="outlined"
            value={completionTimeMonths}
            onChange={(e) => setCompletionTimeMonths(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "4px" }}
                >
                  <span style={{ marginTop: "0px" }}>months</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              inputProps: {
                sx: { textAlign: "right", marginRight: 1 },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            label="Completion Time Surcharge"
            variant="outlined"
            fullWidth
            value={completionTimeSurcharge}
            onChange={handleCompletionTimeSurchargeChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "4px" }}
                >
                  <span style={{ marginTop: "-2px" }}>x</span>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            inputProps={{
              sx: { textAlign: "right", marginRight: 1 },
              inputMode: "decimal",
              pattern: "[0-9]*[.]?[0-9]*",
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 4 }}>
        <TableContainer component={ThemedPaper}>
          <ThemedTable>
            <TableHead>
              <TableRow>
                <TableCell>Money</TableCell>
                <TableCell align="right">Contract Price</TableCell>
                <TableCell align="right">Rate per M</TableCell>
                <TableCell align="right">Premium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    ${row.contractPrice.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">${row.ratePerM}</TableCell>
                  <TableCell align="right">
                    ${row.totalPremium.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ThemedTable>
        </TableContainer>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 17,
        }}
      >
        <ThemedTypography sx={{ mr: 0.25 }}>
          {" "}
          <b>Total Premium:</b> ${totalPremium.toFixed(2)}
        </ThemedTypography>
      </div>
    </div>
  );
};

export default BondRater;
