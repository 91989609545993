import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR } from "../constants";

const ThemedTypography = ({ align, variant, component, gutterBottom, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Typography
      align={align}
      variant={variant}
      component={component}
      gutterBottom={gutterBottom}
      sx={{
        ...sx,
        color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
      }}
    >
      {children}
    </Typography>
  );
};

export default ThemedTypography;
