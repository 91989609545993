import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedDataGrid = ({ key, hideFooter, rows, columns, selectionModel, onRowSelectionModelChange, pageSize, loading, onCellClick, checkboxSelection, onRowClick, disableMultipleSelection, localeText, sx }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <DataGrid
      key={key}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      loading={loading}
      onCellClick={onCellClick}
      checkboxSelection={checkboxSelection}
      onRowClick={onRowClick}
      disableMultipleSelection={disableMultipleSelection}
      selectionModel={selectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      localeText={localeText}
      hideFooter={hideFooter}
      sx={{
				...(darkThemeEnabled && { "--DataGrid-rowBorderColor": DARK_THEME_INPUT_OUTLINE_COLOR }),
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
          {
            outline: "none",
          },
        backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        border: "none",
        '& .MuiDataGrid-columnHeaders': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-filler': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-overlay': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-footerContainer': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-cell': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-treeDataGroupingCell': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiSelect-select': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& p': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
				'& svg': {
          ...(darkThemeEnabled && { color: DARK_THEME_TEXT_COLOR })
				},
				'& button': {
          ...(darkThemeEnabled && { color: DARK_THEME_TEXT_COLOR })
				},
        ...sx
      }}
    />
  );
};

export default ThemedDataGrid;
