import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedIconButton = ({ onClick, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <IconButton
      onClick={onClick}
      sx={{
        ...sx,
        '&:hover': {
          ...(darkThemeEnabled && { backgroundColor: '#393939' }),
        },
        ...(darkThemeEnabled && { color: DARK_THEME_TEXT_COLOR }),
      }}
    >
      {children}
    </IconButton>
  );
};

export default ThemedIconButton;
