import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Snackbar, Paper, TextField, Box, Button, CircularProgress, Grid } from "@mui/material";
import { GENESIS_LOGO_COLOR, SUPPORT_EMAIL } from "../constants";
import { useSelector } from "react-redux";
import { decoratedFetch } from "../req_utils";
import { updateAccount } from "../reducers";
import ThemedPaper from "../components/ThemedPaper";
import ThemedTextField from "./ThemedTextField";
import ThemedButton from "./ThemedButton";

function AccountNotesTab({ border = false }) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [accountNotes, setAccountNotes] = useState("");
    const account = useSelector((state) => state.project.account);
    const [isDirty, setIsDirty] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!account) {
            return;
        }
        setAccountNotes(account?.notes);

    }, [account]);

    const handleAccountNotesChange = (event) => {
        setIsDirty(true);
        setAccountNotes(event.target.value);
    }

    const handleSend = () => {
        setLoading(true);

        const payload = {
            notes: accountNotes,
        };

        decoratedFetch(`/update_account/${account.id}`, {
            method: "PUT",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                switch (response.status) {
                    case 200:
                        return response.json();
                    case 400:
                        setSnackbarMessage("Required fields not filled in.");
                        setSnackbarOpen(true);
                        break;
                    case 500:
                        setSnackbarMessage(
                            `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
                        );
                        setSnackbarOpen(true);
                        break;
                    default:
                        setSnackbarMessage(
                            `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
                        );
                        setSnackbarOpen(true);
                }
                throw new Error("Error message generated");
            })
            .then((data) => {
                const updatedAccount = { ...account, notes: accountNotes };
                dispatch(updateAccount(updatedAccount));
                setIsDirty(false);
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("Account notes saved");
            })
            .catch((error) => {
                console.error("Error:", error);
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage(error);
            });
    };

    return (
        <>
            <Snackbar
                sx={{
                    ".MuiSnackbarContent-root": {
                        backgroundColor: GENESIS_LOGO_COLOR,
                        minWidth: 0,
                    },
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
            <div
              style={{
                width: "100%",
                borderBottom: border ? "1px solid #c4c4c4" : "none",
                borderRight: border ? "1px solid #c4c4c4" : "none",
                borderLeft: border ? "1px solid #c4c4c4" : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ThemedPaper elevation={0} variant='none' sx={{ height: "564px", width: "100%" }}>
                  <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <ThemedTextField
                              sx={{ pt: '16px' }}
                              fullWidth
                              variant="outlined"
                              value={accountNotes}
                              onChange={handleAccountNotesChange}
                              multiline
                              rows={19.5}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          {loading ? (
                              <Box
                                  sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      pt: 10,
                                      height: "36px",
                                  }}
                              >
                                  <CircularProgress size="30px" sx={{ mr: "19px", mt: "4px" }} />
                              </Box>
                          ) : (
                              <Box
                                  sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      pt: 0,
                                      height: "36px",
                                  }}
                              >
                                  <ThemedButton
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSend}
                                      disabled={!isDirty}
                                  >
                                      Save
                                  </ThemedButton>
                              </Box>
                          )}
                      </Grid>
                  </Grid>
                </ThemedPaper>
            </div>
          </div>
        </>
    );
}

export default AccountNotesTab;
