import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  Link,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DashboardPage from "./pages/DashboardPage";
import ProjectsPage from "./pages/ProjectsPage";
import AccountsPage from "./pages/AccountsPage";
import AdminPage from "./pages/AdminPage";
import SettingsPage from "./pages/SettingsPage";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import ForgotYourPasswordPage from "./pages/ForgotYourPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";
import {
  DARK_THEME_APP_BAR_COLOR,
  DARK_THEME_BODY_COLOR,
  DARK_THEME_TEXT_COLOR,
  LIGHT_THEME_BODY_COLOR,
  LIGHT_THEME_TEXT_COLOR,
  GENESIS_LOGO_SECONDARY_COLOR,
  GENESIS_LOGO_COLOR,
  SUPPORT_EMAIL,
  LG_DRAWER_WIDTH,
} from "./constants";
import { decoratedFetch } from "./req_utils";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { useMediaQuery, Collapse } from "@mui/material";
import { setDarkThemeEnabled, partiallyUpdateEmployee } from "./reducers";

const openedMixin = (theme) => ({
  width: LG_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, darkThemeEnabled }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: darkThemeEnabled ? DARK_THEME_APP_BAR_COLOR : GENESIS_LOGO_COLOR,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: LG_DRAWER_WIDTH,
    width: `calc(100% - ${LG_DRAWER_WIDTH}px)`,
    backgroundColor: darkThemeEnabled ? DARK_THEME_APP_BAR_COLOR : GENESIS_LOGO_COLOR,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: LG_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ProtectedRoute = ({
  isAuthorized,
  children,
  redirectPath = "/login",
}) => {
  return isAuthorized ? children : <Navigate to={redirectPath} replace />;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppContent = () => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  const isAuthenticated = useSelector((state) => state.authenticated);
  const isEmployeeAdmin = useSelector(
    (state) =>
      state.employee && state.employee.employee_type === "Administrator",
  );
  const loc = useLocation();
  const isUnprotectedPage =
    loc.pathname === "/signup" ||
    loc.pathname === "/login" ||
    loc.pathname === "/forgot-your-password" ||
    loc.pathname === "/reset-password";
  const shouldRenderHeaderAndSidebar = isAuthenticated && !isUnprotectedPage;
  const unselectedIconColor = darkThemeEnabled ? "#8f8f8f" : "#585858";
  const selectedIconColor = darkThemeEnabled ? DARK_THEME_TEXT_COLOR : GENESIS_LOGO_COLOR;
  const dispatchState = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const path = useLocation().pathname;
  let initialSelectedPage = "Dashboard";
  if (path.length > 1) {
    if (path.startsWith("/project/")) {
      initialSelectedPage = "Projects";
    } else {
      initialSelectedPage = path.charAt(1).toUpperCase() + path.slice(2);
    }
  }
  const [selectedPage, setSelectedPage] = useState(initialSelectedPage);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const persistDarkThemeSetting = newDarkThemeSetting => {
    decoratedFetch("/set_dark_theme_enabled", {
        method: "POST" ,
        body: JSON.stringify({ enabled: newDarkThemeSetting })
      })
      .then((response) => {
        if (response.status === 200) {
          dispatchState(partiallyUpdateEmployee({ dark_theme_enabled: newDarkThemeSetting }));
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        console.log(`Error occurred setting dark theme: ${error}`);
      });
  };

  const handleSignOut = () => {
    decoratedFetch("/log_out", { method: "POST" })
      .then((response) => {
        console.log("Redirecting to login...");
        dispatchState({ type: "mark_as_unauthenticated" });
      })
      .catch((error) => {
        console.log(`An unknown error occurred: ${error}`);
      });
  };

  useEffect(() => {
    decoratedFetch("/look_up_employee")
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 404:
            console.log("User does not exist");
            break;
          case 500:
            console.log(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            console.log(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then((data) => {
        dispatchState({ type: "mark_as_authenticated", employee: data });
        dispatchState(setDarkThemeEnabled(data.dark_theme_enabled));
      })
      .catch((error) => {
        console.log(`Unknown error: ${error}`);
      });
  }, []);

  useEffect(() => {
    if (!isUnprotectedPage) {
      document.body.style.backgroundColor = darkThemeEnabled ? DARK_THEME_BODY_COLOR : LIGHT_THEME_BODY_COLOR;
    }
  }, [loc, darkThemeEnabled]);

  return (
    <>
			<ScrollToTop/>
      {shouldRenderHeaderAndSidebar ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} darkThemeEnabled={darkThemeEnabled}>
            <Toolbar>
              <Collapse
                in={!open}
                orientation="horizontal"
                timeout={260}
                easing="cubic-bezier(0.4, 0, 0.2, 1)"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: "26px",
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Collapse>
              <Box
                sx={{
                  "& img": {
                    width: "138px",
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <img
                  src="/genesis_logo_inverted.png"
                  alt="Genesis Floor Covering Inc. logo"
                />
                <Box>
                  <IconButton
                    sx={{ color: 'white', mr: '10px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      const newDarkThemeSetting = !darkThemeEnabled;
                      dispatchState(setDarkThemeEnabled(newDarkThemeSetting));
                      persistDarkThemeSetting(newDarkThemeSetting);
                    }}
                  >
                    { darkThemeEnabled ? <LightModeIcon /> : <Brightness3Icon /> }
                  </IconButton>
                  <Button
                    sx={{
                      fontSize: 11,
                      "&:hover": {
                        backgroundColor: darkThemeEnabled ? DARK_THEME_BODY_COLOR : GENESIS_LOGO_SECONDARY_COLOR,
                      },
                    }}
                    color="inherit"
                    onClick={handleSignOut}
                  >
                    <b>Sign Out</b>
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            onClick={() => isMobile && handleDrawerClose()}
            sx={{
              "& .MuiDrawer-paper": {
                backgroundColor: darkThemeEnabled ? DARK_THEME_BODY_COLOR : LIGHT_THEME_BODY_COLOR,
                border: "none",
              },
            }}
          >
            <DrawerHeader>
              <IconButton sx={{ color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR }} onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <List sx={{ padding: 0 }}>
              {["Dashboard", "Projects", "Accounts", "Admin", "Settings"].map(
                (text, index) =>
                  (text !== "Admin" || isEmployeeAdmin) && (
                    <Link
                      to={`/${text === "Dashboard" ? "" : text.toLowerCase()}`}
                      style={{
                        textDecoration: "none",
                        color:
                          selectedPage === text
                            ? selectedIconColor
                            : unselectedIconColor,
                      }}
                      key={text}
                    >
                      <ListItem
                        button
                        onClick={() => setSelectedPage(text)}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {index === 0 && (
                              <DashboardIcon
                                sx={{
                                  color:
                                    selectedPage === text
                                      ? selectedIconColor
                                      : unselectedIconColor,
                                }}
                              />
                            )}
                            {index === 1 && (
                              <WorkIcon
                                sx={{
                                  color:
                                    selectedPage === text
                                      ? selectedIconColor
                                      : unselectedIconColor,
                                }}
                              />
                            )}
                            {index === 2 && (
                              <PeopleIcon
                                sx={{
                                  color:
                                    selectedPage === text
                                      ? selectedIconColor
                                      : unselectedIconColor,
                                }}
                              />
                            )}
                            {index === 3 && (
                              <AdminPanelSettingsIcon
                                sx={{
                                  color:
                                    selectedPage === text
                                      ? selectedIconColor
                                      : unselectedIconColor,
                                }}
                              />
                            )}
                            {index === 4 && (
                              <SettingsIcon
                                sx={{
                                  color:
                                    selectedPage === text
                                      ? selectedIconColor
                                      : unselectedIconColor,
                                }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={text}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ),
              )}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, width: `calc(100% - ${LG_DRAWER_WIDTH}px)` }}
          >
            <DrawerHeader />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute isAuthorized={isAuthenticated}>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/forgot-your-password"
                element={<ForgotYourPasswordPage />}
              />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route
                path="/projects"
                element={
                  <ProtectedRoute isAuthorized={isAuthenticated}>
                    <ProjectsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project/:project_id"
                element={
                  <ProtectedRoute isAuthorized={isAuthenticated}>
                    <ProjectDetailsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/accounts"
                element={
                  <ProtectedRoute isAuthorized={isAuthenticated}>
                    <AccountsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute
                    isAuthorized={isAuthenticated && isEmployeeAdmin}
                    redirectPath="/"
                  >
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute isAuthorized={isAuthenticated}>
                    <SettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/forgot-your-password"
              element={<ForgotYourPasswordPage />}
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/projects"
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <ProjectsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/project/:project_id"
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <ProjectDetailsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <AccountsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute
                  isAuthorized={isAuthenticated && isEmployeeAdmin}
                  redirectPath="/"
                >
                  <AdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      )}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
