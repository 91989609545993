import React, { useState, useEffect } from "react";
import { GridToolbar} from "@mui/x-data-grid-pro";
import { Button, Snackbar } from "@mui/material";
import SavedMaterialItemDialog from "./dialogs/SavedMaterialItemDialog";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";
import ThemedDataGridPro from "./ThemedDataGridPro";

const columns = [
  { field: "material_type", headerName: "Type", flex: 1 },
  { field: "material_style", headerName: "Style", flex: 1 },
  { field: "material_color", headerName: "Color", flex: 1 },
  { field: "material_vendor", headerName: "Manufacturer", flex: 1 },
  { field: "material_size", headerName: "Size", flex: 1 },
  {
    field: "size_unit",
    headerName: "Unit",
    flex: 1,
    renderCell: (params) => {
      return params.value.name;
    },
  },
  {
    field: "unit_price",
    headerName: "Price",
    flex: 1,
    // renderCell: (params) => {
    //     const value = params.value;
    //     return value ? `$${value.toFixed(2)}` : '';
    // }
  },
];

const SavedMaterials = ({ value, index }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [savedMaterialItems, setSavedMaterialItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [savedMaterialItemsLoading, setSavedMaterialItemsLoading] =
    useState(false);

  const listSavedMaterialItems = () => {
    decoratedFetch("/list_saved_material_items")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setSavedMaterialItemsLoading(false);
        setSavedMaterialItems(data);
      })
      .catch((error) => {
        setSavedMaterialItemsLoading(true);
        console.error(`Error fetching data: ${error}`);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    setSavedMaterialItemsLoading(true);
    listSavedMaterialItems();
  }, []);

  const handleRowClick = (params) => {
    setSelectedItem(params.row);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedItem(null);
    setOpen(false);
    listSavedMaterialItems(); // Refresh data
  };

  return (
    <div>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 5 }}
          onClick={() => setOpen(true)}
        >
          Create Material Item
        </Button>
      </div>
      <div style={{ height: 'auto', width: "100%" }}>
        <ThemedDataGridPro
          rows={savedMaterialItems}
          slots={{ toolbar: GridToolbar }}
          columns={columns}
          onRowClick={handleRowClick}
          loading={savedMaterialItemsLoading}
          localeText={{ noRowsLabel: "No materials" }}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
              {
                outline: "none",
              },
          }}
        />
      </div>
      <SavedMaterialItemDialog
        isOpen={open}
        materialItem={selectedItem}
        handleClose={handleDialogClose}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </div>
  );
};

export default SavedMaterials;
