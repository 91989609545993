import React, { useRef, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  MenuItem,
  InputAdornment,
  Grid,
  IconButton,
  Snackbar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { decoratedFetch } from "../req_utils";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createAutogeneratedLog,
  createProjectAssignmentLog,
} from "../log_utils";
import {
  updateAccount,
  markProjectClean,
  updateProject,
  refreshLogs,
} from "../reducers";
import { PROJECT_STATUSES, GENESIS_LOGO_COLOR } from "../constants";
import ThemedTextField from "../components/ThemedTextField";
import ThemedSelect from "../components/ThemedSelect";
import ThemedDatePicker from "../components/ThemedDatePicker";
import ThemedButton from "../components/ThemedButton";
import ThemedFormControlLabel from "../components/ThemedFormControlLabel";

dayjs.extend(utc);

const ProjectDetails = ({ projectData }) => {
  const dispatchState = useDispatch();
  const initialStatusId = useRef(null);
  const initialAssigneeId = useRef(null);
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const isEmployeeAdmin = employee.employee_type == "Administrator";
  const { project_id } = useParams();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [branchAddresses, setBranchAddresses] = useState([]);
  const [branchAddressId, setBranchAddressId] = useState("");
  const [measuredById, setMeasuredById] = useState("");
  const [localProjectName, setLocalProjectName] = useState(project?.name);
  const [localProjectNotes, setLocalProjectNotes] = useState(project?.notes || "");
  const [trigger, setTrigger] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  const dateTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                marginTop: project?.project_project_completion_date ? "0px" : "-7px",
              },
            },
          },
        },
      }),
    [project],
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleLocalProjectNameChange = (event) =>
    setLocalProjectName(event.target.value);

  const handleStatusChange = (index) => {
    const newStatus = { id: index + 1, name: PROJECT_STATUSES[index] };
    if (newStatus.id === project?.status?.id) {
      return;
    }
    updateProjectStatusState(newStatus);
  };

  const handleProjectNameBlur = () => {
    dispatchState(updateProject({ ...project, name: localProjectName }));
  };

  const handleAssigneeChange = (event) => {
    updateProjectAssigneeState(event);
  };

  const updateProjectStatusState = (newStatus) => {
    const updatedProject = {
      ...project,
      status: newStatus,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectAssigneeState = (event) => {
    const newAssigneeId = event.target.value;
    if (newAssigneeId === project?.assignee.id) {
      return;
    }
    const updatedProject = {
      ...project,
      assignee: employees.find((employee) => employee.id === newAssigneeId),
    };
    dispatchState(updateProject(updatedProject));
  };

  useEffect(() => {
    setLocalProjectName(project?.name || "");
    setLocalProjectNotes(project?.project_notes || "");
    setSaveButtonDisabled(!project?.isDirty);
    if (initialStatusId.current === null && project?.status?.id) {
      initialStatusId.current = project.status.id;
    }
    if (initialAssigneeId.current === null && project?.assignee?.id) {
      initialAssigneeId.current = project.assignee.id;
    }
  }, [project]);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev);
  };

  useEffect(() => {
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
  }, []);

  const handleSave = () => {
    if (!project?.name) {
      setSnackbarOpen(true);
      setSnackbarMessage("Project name required");
      return;
    }
    setSaveButtonDisabled(true);

    const payload = {
      ...project,
      status_id: project?.status?.id,
      name: project?.name,
      pm_employee_id: project?.pm_employee?.id,
      project_start_date: project?.project_start_date,
      project_completion_date: project?.project_completion_date,
      certified_payroll: project?.certified_payroll,
      payment_performance_bond: project?.payment_performance_bond,
      notes: project?.localProjectNotes,
      genesis_branch_address_id: project?.genesis_branch_address?.id,
      sales_employee_id: project?.sales_employee?.id,
      sales_employee_first_name: project?.sales_employee?.first_name,
      sales_employee_last_name: project?.sales_employee?.last_name,
      measured_by_employee_id: project?.measured_by_employee?.id,
      assignee_id: project?.assignee?.id,
    };

    decoratedFetch(`/update_project/${project_id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setSnackbarOpen(true);
        setSnackbarMessage("Project saved");
        dispatchState(markProjectClean());
        if (initialStatusId.current !== project?.status?.id) {
          createAutogeneratedLog(
            project.id,
            employee.id,
            `Project status changed to \"${project.status.name}\" by ${employee.first_name} ${employee.last_name}`
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialStatusId.current = project.status.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        } else if (initialAssigneeId.current?.id !== project?.assignee?.id) {
          createProjectAssignmentLog(
            project,
            employee?.id,
            `${employee.first_name} ${employee.last_name} assigned project to ${project.assignee.first_name} ${project.assignee.last_name}`
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialAssigneeId.current = project.assignee.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        }
      })
      .catch((error) => {
        console.error("Error saving project", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to save project");
        setSaveButtonDisabled(false);
      });
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false);
  };

  const handleAccountUpdate = (newAccount) => {
    dispatchState(updateAccount(newAccount));
  };

  const handleBranchChange = (newBranchAddressId) => {
    if (newBranchAddressId === project?.genesis_branch_address?.id) {
      return;
    }
    dispatchState(
      updateProject({
        ...project,
        genesis_branch_address: branchAddresses.find(
          (branchAddress) => branchAddress.id === newBranchAddressId
        ),
      })
    );
  };

  const updateMeasuredBy = event => {
    const newMeasuredById = event.target.value;
    if (newMeasuredById === project?.measured_by_employee?.id) {
      return;
    }
    const updatedProject = {
      ...project,
      measured_by_employee: employees.find((employee) => employee.id === newMeasuredById),
    };
    dispatchState(updateProject(updatedProject));
  };

  useEffect(() => {
    setBranchAddressId(project?.genesis_branch_address?.id || "");
    setMeasuredById(project?.measured_by_employee?.id || "");
  }, [project]);

  useEffect(() => {
    decoratedFetch("/list_employees")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setEmployees(data);
        } else {
          setEmployees([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setEmployees([]);
      });
    decoratedFetch("/list_branch_addresses")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setBranchAddresses(data);
        } else {
          setBranchAddresses([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setBranchAddresses([]);
      });
  }, []);

  const updateProjectSR = (event) => {
    const newSalesRepId = event.target.value;
    if (newSalesRepId === project?.sales_employee?.id) {
      return;
    }
    const updatedProject = {
      ...project,
      sales_employee: employees.find(
        (employee) => employee.id === newSalesRepId
      ),
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectPM = (event) => {
    const newPMId = event.target.value;
    if (newPMId === project?.pm_employee?.id) {
      return;
    }
    const updatedProject = {
      ...project,
      pm_employee: employees.find((employee) => employee.id === newPMId),
    };
    dispatchState(updateProject(updatedProject));
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <Grid container spacing={2} paddingTop={0.9}>
        <Grid
          item
          xs={12}
          sx={{ marginTop: "2.5px", display: { xs: "block", md: "none" } }}
        >
          <ThemedTextField
            label="Project Name"
            fullWidth
            size="small"
            variant="outlined"
            value={localProjectName}
            onChange={handleLocalProjectNameChange}
            onBlur={handleProjectNameBlur}
            InputLabelProps={{ shrink: project?.name?.length > 0 }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ marginTop: "4px", display: { xs: "block", md: "none" } }}
        >
          <ThemedTextField
            label="ID"
            fullWidth
            size="small"
            variant="outlined"
            value={project?.custom_id || ""}
            style={{ pointerEvents: "none" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ marginTop: "4px", display: { xs: "block", md: "none" } }}
        >
          <div style={{ display: "flex" }}>
            <ThemedTextField
              sx={{ pr: "6px" }}
              style={{ pointerEvents: "none" }}
              label="Account"
              fullWidth
              size="small"
              variant="outlined"
              value={localProjectName}
              onChange={handleLocalProjectNameChange}
              onBlur={handleProjectNameBlur}
              InputLabelProps={{ shrink: project?.name?.length > 0 }}
            />
            <IconButton onClick={() => setAccountDialogOpen(true)}>
              <EditIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ marginTop: "4px", display: { xs: "block", md: "none" } }}
        >
          <ThemedSelect
            id="assignee-select"
            label="Assignee"
            value={project?.assignee ? project.assignee?.id : ""}
            onChange={handleAssigneeChange}
            size="small"
            fullWidth
            renderValue={(selectedId) => {
              const selected = employees.find((e) => e.id === selectedId);
              return selected
                ? `${selected.first_name} ${selected.last_name}`
                : "";
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ marginTop: "4px", display: { xs: "block", md: "none" } }}
        >
          <ThemedSelect
            id="status-select"
            value={project?.status ? project.status?.id - 1 : ""}
            onChange={(e) => handleStatusChange(e.target.value)}
            label="Status"
            size="small"
            fullWidth
            renderValue={(selectedId) => PROJECT_STATUSES[selectedId]}
            MenuProps={{ disableScrollLock: true }}
          >
            {PROJECT_STATUSES.map((proj_status, i) => (
              <MenuItem key={i} value={i}>
                {proj_status}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={6} sx={{ marginTop:{xs: "4px", md: "20px" }}}>
          <ThemedSelect
            id="branch-select"
            size="small"
            value={branchAddressId}
            onChange={(e) => handleBranchChange(e.target.value)}
            label="Genesis Branch Address"
            fullWidth
            renderValue={(selectedId) => {
              const selected = branchAddresses.find(
                (branchAddress) => branchAddress.id === selectedId,
              );
              return selected
                ? `${selected.line_1}, ${selected.city} ${selected.state} ${selected.zip}`
                : "";
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {branchAddresses.map((branch) => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.line_1}, {branch.city} {branch.state} {branch.zip}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={6} sx={{ marginTop:{xs: "4px", md: "20px" }}}>
          <ThemedSelect
            id="sales-select"
            value={project?.sales_employee ? project.sales_employee?.id : ""}
            onChange={updateProjectSR}
            label="Sales Rep."
            size="small"
            fullWidth
            renderValue={(selectedId) => {
              const selected = employees.find((e) => e.id === selectedId);
              return selected
                ? `${selected.first_name} ${selected.last_name}`
                : "";
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "4px" }}>
          <ThemedSelect
            id="pm-select"
            value={project?.pm_employee ? project.pm_employee?.id : ""}
            onChange={updateProjectPM}
            label="Project Manager"
            fullWidth
            size="small"
            renderValue={(selectedId) => {
              const selected = employees.find((e) => e.id === selectedId);
              return selected
                ? `${selected.first_name} ${selected.last_name}`
                : "";
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={6} sm={5} md={6} sx={{ marginTop: "4px" }}>
          <ThemedSelect
            id="mb-select"
            value={measuredById}
            size="small"
            onChange={updateMeasuredBy}
            label="Measured By"
            fullWidth
            renderValue={(selectedId) => {
              const selected = employees.find((e) => e.id === selectedId);
              return selected
                ? `${selected.first_name} ${selected.last_name}`
                : "";
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {employees
              .filter((employee) => employee.enabled)
              .map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.first_name} {employee.last_name}
                </MenuItem>
              ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "4px" }}>
          <ThemeProvider theme={dateTheme}>
            <ThemedDatePicker
              label="Project Start Date"
              value={
                project?.project_start_date ? dayjs.utc(project?.project_start_date) : null
              }
              size="small"
              onChange={(newStartDate) =>
                dispatchState(
                  updateProject({ ...project, project_start_date: newStartDate })
                )
              }
              sx={{
                "& .MuiInputBase-input": {
                  padding: "8.5px 0px 8.5px 14px",
                  width: "100%",
                },
                width: "100%",
              }}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "4px" }}>
          <ThemeProvider theme={dateTheme}>
            <ThemedDatePicker
              label="Project Completion Date"
              value={
                project?.project_completion_date
                  ? dayjs.utc(project?.project_completion_date)
                  : null
              }
              size="small"
              onChange={(newCompletionDate) =>
                dispatchState(
                  updateProject({ ...project, project_completion_date: newCompletionDate })
                )
              }
              sx={{
                "& .MuiInputBase-input": {
                  padding: "8.5px 0px 8.5px 14px",
                  width: "100%",
                },
                width: "100%",
              }}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={6}>
          <ThemedFormControlLabel
            control={
              <Checkbox
                checked={project?.certified_payroll || false}
                onChange={(e) =>
                  dispatchState(
                    updateProject({
                      ...project,
                      certified_payroll: e.target.checked,
                    })
                  )
                }
                color="primary"
              />
            }
            label="Certified Payroll"
          />
        </Grid>
        <Grid item xs={6}>
          <ThemedFormControlLabel
            control={
              <Checkbox
                checked={project?.payment_performance_bond || false}
                onChange={(e) =>
                  dispatchState(
                    updateProject({
                      ...project,
                      payment_performance_bond: e.target.checked,
                    })
                  )
                }
                color="primary"
              />
            }
            label="Payment + Performance Bond"
          />
        </Grid>
        <Grid item xs={12}>
          <ThemedTextField
            label="Notes"
            multiline
            rows={isSmallScreen || isMdScreen ? 1 : 11}
            fullWidth
            variant="outlined"
            value={localProjectNotes}
            onChange={(e) => setLocalProjectNotes(e.target.value)}
            onBlur={() =>
              dispatchState(updateProject({ ...project, project_notes: localProjectNotes }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: { xs: "block", md: "none" } }}>
          <ThemedButton
            fullWidth
            sx={{ ml: 0, mt: "6px" }}
            onClick={handleSave}
            startIcon={<SaveIcon />}
            size="medium"
            variant="contained"
            disabled={saveButtonDisabled}
          >
            Save
          </ThemedButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectDetails;
