import React, { useState, useEffect, useRef } from "react";
import {
  Snackbar,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Popper,
  Paper,
  ListItemButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { decoratedFetch } from "../req_utils";
import { useParams } from "react-router-dom";
import { SUPPORT_EMAIL, GENESIS_LOGO_COLOR } from "../constants";
import { refreshLogs } from "../reducers";
import ThemedTextField from "../components/ThemedTextField";
import ThemedButton from "../components/ThemedButton";
import ThemedTypography from "../components/ThemedTypography";

const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const TIME_OPTIONS = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

function LogTab({ value, index, projectName, projectCustomId }) {
  const dispatch = useDispatch();
  const refreshLogList = useSelector((state) => state.refreshLogs);
  const [inputText, setInputText] = useState("");
  const [entries, setEntries] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [mentionList, setMentionList] = useState([]);
  const [mentionOpen, setMentionOpen] = useState(false);
  const [mentionIndex, setMentionIndex] = useState(0);
  const [activeMentionIndex, setActiveMentionIndex] = useState(0);
  const inputRef = useRef(null);
  const employee = useSelector((state) => state.employee);
  const { project_id } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const convertAndFormatTimestamp = (timestamp) => {
    const formattedDate = new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(
      timestamp,
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", TIME_OPTIONS).format(
      timestamp,
    );
    return `${formattedDate} ${formattedTime}`;
  };

  const getLogs = () => {
    decoratedFetch(`/list_log_items/${project_id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) =>
        setEntries(
          data.map((item) => {
            return {
              ...item,
              created_time: convertAndFormatTimestamp(
                new Date(item.created_time),
              ),
            };
          }),
        ),
      )
      .catch((error) => {
        console.error("Error fetching logs:", error);
        setSnackbarMessage("Failed to fetch logs");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }

    getLogs();
    const fetchEmployees = async () => {
      const api = `/list_employees`;
      try {
        const response = await decoratedFetch(api, { method: "GET" });
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [project_id]);

  useEffect(() => {
    if (refreshLogList) {
      getLogs();
      dispatch(refreshLogs(false));
    }
  }, [refreshLogList]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSubmitDisabled(!value.length);
    setInputText(value);

    const lastChar = value.slice(-1);
    if (lastChar === "@") {
      setMentionOpen(true);
      setMentionIndex(value.length);
      setMentionList(employees);
    } else if (mentionOpen) {
      const mentionQuery = value.slice(mentionIndex);
      const filteredList = employees.filter((emp) =>
        `${emp.first_name} ${emp.last_name}`
          .toLowerCase()
          .includes(mentionQuery.toLowerCase()),
      );
      if (filteredList.length < 1) {
        setMentionOpen(false);
      }
      setMentionList(filteredList);
      setActiveMentionIndex(0);
    }
  };

  const handleMentionSelect = (employee) => {
    const mentionText = `${employee.first_name} ${employee.last_name}`;
    const mentionQuery = inputText.slice(mentionIndex);
    const updatedText =
      inputText.slice(0, mentionIndex - 1) +
      "@" +
      mentionText +
      " " +
      inputText.slice(mentionIndex + mentionQuery.length);
    setInputText(updatedText);
    setMentionOpen(false);
  };

  const extractTaggedUsers = (text) => {
    const taggedUsers = [];
    const regex = /@([a-zA-Z]+ [a-zA-Z]+)/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
      const fullName = match[1];
      const employee = employees.find(
        (emp) =>
          `${emp.first_name} ${emp.last_name}`.toLowerCase() ===
          fullName.toLowerCase(),
      );
      if (employee) {
        taggedUsers.push(employee);
      }
    }
    return taggedUsers;
  };

  const handleButtonClick = () => {
    const taggedUsers = extractTaggedUsers(inputText);
    if (taggedUsers.length > 10) {
      setSnackbarMessage(`Max number of tags per log (10) exceeded`);
      setSnackbarOpen(true);
      return;
    }

    const currentDateTime = new Date();
    const newEntry = {
      created_time: convertAndFormatTimestamp(currentDateTime),
      created_by_employee: {
        first_name: employee.first_name,
        last_name: employee.last_name,
      },
      content: inputText,
    };
    setEntries([newEntry, ...entries]);
    handleSubmit(inputText, taggedUsers);
    setInputText("");
    setSubmitDisabled(true);
  };

  const handleSubmit = (inputText, taggedUsers) => {
    const api = "/create_log_item";
    decoratedFetch(api, {
      method: "POST",
      body: JSON.stringify({
        project_id: project_id,
        project_name: projectName,
        project_custom_id: projectCustomId,
        content: inputText,
        created_by_employee_id: employee.id,
        tagged_users: taggedUsers.map((user) => user.id),
      }),
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSnackbarMessage(`Log added`);
          setSnackbarOpen(true);
          return;
        }
        switch (response.status) {
          case 500:
            setSnackbarMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setSnackbarMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        setSnackbarOpen(true);
        throw new Error();
      })
      .catch((error) => {
        console.error(`Error adding log item: ${error}`);
      });
  };

  const handleKeyDown = (event) => {
    if (mentionOpen) {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setActiveMentionIndex((prevIndex) =>
            prevIndex + 1 < mentionList.length ? prevIndex + 1 : prevIndex,
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setActiveMentionIndex((prevIndex) =>
            prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex,
          );
          break;
        case "Enter":
          event.preventDefault();
          handleMentionSelect(mentionList[activeMentionIndex]);
          break;
        default:
          break;
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (!inputText) {
        return;
      }
      setSubmitDisabled(false);
      handleButtonClick();
    }
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div style={{ marginTop: 10 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginBottom: 2,
          }}
        >
          <ThemedTextField
            label="New Entry"
            variant="outlined"
            size="small"
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            fullWidth
            inputRef={inputRef}
            sx={{ flexGrow: 1 }}
          />
          <ThemedButton
            variant="contained"
            onClick={handleButtonClick}
            disabled={submitDisabled}
          >
            Submit
          </ThemedButton>
          <Popper
            open={mentionOpen}
            anchorEl={inputRef.current}
            placement="bottom-start"
            style={{ zIndex: 1300 }}
          >
            <Paper>
              <List>
                {mentionList.map((employee, index) => (
                  <ListItemButton
                    key={employee.id}
                    selected={index === activeMentionIndex}
                    onClick={() => handleMentionSelect(employee)}
                  >
                    {`${employee.first_name} ${employee.last_name}`}
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Popper>
        </Box>
        <List
          style={{
            height: "489px",
            overflow: "auto",
            borderRadius: "4px",
          }}
        >
          {entries.length ? (
            entries.map((entry, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    entry.autogenerated ? (
                      <ThemedTypography component="span">
                        {`[${entry?.created_time}]`}{" "}
                        <span style={{ fontStyle: "italic" }}>
                          {entry?.content}
                        </span>
                      </ThemedTypography>
                    ) : (
                      <ThemedTypography component="span">
                        {`[${entry?.created_time}]`}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {`${entry.created_by_employee.first_name} ${entry.created_by_employee.last_name}`}
                        </span>
                        :{" "}
                        {entry.content.split(" ").map((word, i, arr) => {
                          if (word.startsWith("@")) {
                            word = word.slice(1);
                          }
                          if (i < arr.length - 1) {
                            const fullName = `${word} ${arr[i + 1]}`;
                            const employee = employees.find(
                              (emp) =>
                                `${emp.first_name} ${emp.last_name}`.toLowerCase() ===
                                fullName.toLowerCase(),
                            );
                            if (employee) {
                              return (
                                <React.Fragment key={i}>
                                  <span style={{ color: "#2E67D1" }}>
                                    {fullName}
                                  </span>{" "}
                                </React.Fragment>
                              );
                            }
                          }
                          if (!arr[i - 1]?.startsWith("@") || i === 0) {
                            return word + " ";
                          }
                          return "";
                        })}
                      </ThemedTypography>
                    )
                  }
                />
              </ListItem>
            ))
          ) : (
            <Box
              sx={{ height: "100%" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ThemedTypography component="span" variant="subtitle1">No logs</ThemedTypography>
            </Box>
          )}
        </List>
      </div>
    </>
  );
}

export default LogTab;
