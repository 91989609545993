import React, { useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedSelect = ({ id, transparentLabel, formControlSize, formHelperText: formHelperTextProp, MenuProps, size, label, value, onChange, fullWidth, renderValue, formControlSx, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  let formHelperText = null;
  if (formHelperTextProp) {
    formHelperText = React.cloneElement(formHelperTextProp, { sx: { color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR } });
  }

  return (
    <FormControl
      sx={{
        ...formControlSx,
        width: "100%",
      }}
      size={formControlSize}
    >
      <InputLabel
        size={size}
        sx={{
          ...sx,
          backgroundColor: darkThemeEnabled ? (transparentLabel ? 'transparent' : DARK_THEME_PAPER_COLOR) : LIGHT_THEME_PAPER_COLOR,
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        }}
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        value={value}
        onChange={onChange}
        size={size}
        fullWidth={fullWidth}
        renderValue={renderValue}
        MenuProps={MenuProps}
        sx={{
          ...sx,
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
          '& svg': {
            ...(darkThemeEnabled && { color: DARK_THEME_TEXT_COLOR }),
          },
          "& .MuiOutlinedInput-notchedOutline": {
            ...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR }),
          },
        }}
      >
        {children}
      </Select>
      {formHelperText}
    </FormControl>
  );
};

export default ThemedSelect;
