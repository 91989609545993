import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_PAPER_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedTypography = ({ elevation, variant = 'outlined', sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Paper
      elevation={elevation}
      variant={variant}
      sx={{
        ...sx,
        backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        ...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR, borderWidth: '0.5px' }),
      }}
    >
      {children}
    </Paper>
  );
};

export default ThemedTypography;
