import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress, Snackbar } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import * as mime from "react-native-mime-types";
import heic2any from "heic2any";
import {
  GENESIS_LOGO_COLOR,
  SUPPORT_EMAIL,
  SUPPORTED_UPLOAD_FILE_TYPES,
} from "../constants";
import { decoratedAxiosPost } from "../req_utils";
import { refreshLogs } from "../reducers";
import { createAutogeneratedLog } from "../log_utils";
import ThemedIconButton from "./ThemedIconButton";

const MAX_UPLOAD_SIZE_MB = 200;
const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;
const MAX_NUM_FILES = 50;

const FileUpload = ({ onSuccess }) => {
  const dispatchState = useDispatch();
  const employee = useSelector((state) => state.employee);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let { project_id } = useParams();

  // Ref to trigger file input click programmatically
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertHeicToJpeg = async (heicFile) => {
    try {
      const blob = await heic2any({
        blob: heicFile,
        toType: "image/jpeg",
        quality: 0.8,
      });
      return new File([blob], heicFile.name.replace(/\.heic$/, ".jpeg"), {
        type: "image/jpeg",
      });
    } catch (error) {
      console.error("Error converting HEIC to JPEG:", error);
      return heicFile; // Return the original file if conversion fails
    }
  };

  const handleFileUpload = async (event) => {
    console.log("btn press");
    setLoading(true);
    let files = Array.from(event.target.files);

    if (files.length === 0) {
      setLoading(false);
      return;
    }

    if (files.length > MAX_NUM_FILES) {
      setSnackbarMessage(`Max number of files (${MAX_NUM_FILES}) exceeded`);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    const uploadSize = files.reduce((totalSize, file) => totalSize + file.size, 0);
    if (uploadSize > MAX_UPLOAD_SIZE) {
      setSnackbarMessage(`Max upload size (${MAX_UPLOAD_SIZE_MB}MB) exceeded`);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    // Check if there are any HEIC files
    let containsHeic = files.some(file => file.type === "image/heic");

    if (containsHeic) {
      setSnackbarMessage("Converting HEIC to JPEG...");
      setSnackbarOpen(true);

      // Convert HEIC files to JPEG
      files = await Promise.all(
        files.map((file) => file.type === "image/heic" ? convertHeicToJpeg(file) : file)
      );

      setSnackbarOpen(false);
    }

    try {
      const { data } = await decoratedAxiosPost("/get_s3_presigned_urls_for_upload", {
        project_id: project_id,
        files: files.map(file => ({
          name: file.name,
          type: file.type,
        })),
      });

      await Promise.all(
        files.map((file, i) =>
          axios.put(data[i].presigned_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
        )
      );

      await decoratedAxiosPost("/create_project_files_metadata", {
        project_id: project_id,
        files: files.map((file, i) => ({
          name: file.name,
          type: mime.extension(file.type),
          s3_key: data[i].s3_key,
        })),
      });

      setSnackbarMessage(`File${files.length > 1 ? "s" : ""} uploaded`);
      setSnackbarOpen(true);
      setLoading(false);

      createAutogeneratedLog(
        project_id,
        employee?.id,
        files.length === 1
          ? `File \"${files[0].name}\" uploaded by ${employee.first_name} ${employee.last_name}`
          : `${files.length} files uploaded by ${employee.first_name} ${employee.last_name}`
      )
        .then((response) => {
          if (response.status !== 201) {
            throw new Error("Error occurred");
          }
          dispatchState(refreshLogs(true));
        })
        .catch((error) => {
          console.error(`Error creating log: ${error}`);
        });

      onSuccess();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(
        error.response?.data?.msg || `An error occurred when uploading file${files.length > 1 ? "s" : ""}`
      );
      setSnackbarOpen(true);
      console.error(`Error uploading file${files.length > 1 ? "s" : ""}:`, error);
    }
  };

  return (
    <div style={{ marginTop: "4px" }}>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {loading ? (
        <CircularProgress
          size="25px"
          sx={{ marginRight: "8px", marginTop: "7px", marginBottom: "1px" }}
        />
      ) : (
        <div>
          <input
            type="file"
            ref={fileInputRef}
            multiple
            accept={SUPPORTED_UPLOAD_FILE_TYPES}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <ThemedIconButton aria-label="upload picture" onClick={handleIconClick}>
            <FileUploadIcon />
          </ThemedIconButton>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
