import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  Snackbar,
  CircularProgress,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import RemoveIcon from "@mui/icons-material/Remove";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileUpload from "./FileUpload";
import FilePreview from "./FilePreview";
import axios from "axios";
import { refreshFiles } from "../reducers";
import { decoratedFetch, decoratedAxiosPost } from "../req_utils";
import {
  GENESIS_LOGO_COLOR,
  SUPPORT_EMAIL,
  SUPPORTED_PREVIEW_FILE_TYPES,
} from "../constants";
import ThemedDataGrid from "./ThemedDataGrid";
import ThemedIconButton from "./ThemedIconButton";

function FileTab({ value, index }) {
  const refreshFileList = useSelector((state) => state.refreshFiles);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [gridKey, setGridKey] = useState(false);
  const [removeFilesLoading, setRemoveFilesLoading] = useState(false);
  const [downloadingFileId, setDownloadingFileId] = useState("");
  const [previewFile, setPreviewFile] = useState("");
  const [previewFileLink, setPreviewFileLink] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { project_id } = useParams();
  const [fileIdToIdx, setFileIdToIdx] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);

  const columns = [
    { field: "name", headerName: "Name", flex: 4 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "created_date", headerName: "Created Date", flex: 2 },
    {
      field: "actions",
      headerName: "",
      renderCell: (e) => {
        const row = e.row;
        let download = (
          <Grid item>
            <IconButton size="small" onClick={() => handleFileDownload(row)}>
              <DownloadIcon />
            </IconButton>
          </Grid>
        );
        if (row.id === downloadingFileId) {
          download = (
            <CircularProgress size="22px" sx={{ ml: "7px", mt: "14px" }} />
          );
        }
        let preview = (
          <Grid item sx={{ marginRight: "4px" }}>
            <IconButton size="small" onClick={() => handleFilePreview(row)}>
              <VisibilityIcon />
            </IconButton>
          </Grid>
        );
        if (row.id === previewFile?.id && !previewFileLink) {
          preview = (
            <CircularProgress
              size="22px"
              sx={{ mr: "12px", ml: "4px", mt: "14px" }}
            />
          );
        }
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container>
              {preview}
              {download}
            </Grid>
          </Box>
        );
      },
      width: 94,
      sortable: false,
      filterable: false,
    },
  ];

  useEffect(() => {
    if (refreshFileList) {
      listProjectFiles();
      dispatch(refreshFiles(false));
    }
  }, [refreshFileList]);

  const handleFilePreview = async (file) => {
    if (!SUPPORTED_PREVIEW_FILE_TYPES.includes(file.type)) {
      setSnackbarOpen(true);
      setSnackbarMessage("File type not supported for previews");
      return;
    }
    setPreviewFile(file);
    try {
      const { data } = await decoratedAxiosPost(
        "/get_s3_presigned_url_for_download",
        {
          s3_key: file.s3_key,
        },
      );
      setPreviewFileLink(data.presigned_url);
    } catch (error) {
      setPreviewFileLink("");
      setPreviewFile(null);
      setSnackbarOpen(true);
      setSnackbarMessage("An error occurred when previewing file");
      console.error("Error previewing file:", error);
    }
  };

  const handleFileDownload = async (file) => {
    setDownloadingFileId(file.id);
    try {
      const { data } = await decoratedAxiosPost(
        "/get_s3_presigned_url_for_download",
        {
          s3_key: file.s3_key,
        },
      );

      const result = await axios(data.presigned_url, {
        method: "GET",
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([result.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", file.name);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode.removeChild(fileLink);

      setDownloadingFileId("");
    } catch (error) {
      setDownloadingFileId("");
      setSnackbarOpen(true);
      setSnackbarMessage("An error occurred when downloading file");
      console.error("Error downloading file:", error);
    }
  };

  const initializeFileIdToIdx = (files) => {
    let newFileIdToIdx = {};
    files.forEach((file, i) => {
      newFileIdToIdx[file.id] = i;
    });
    setFileIdToIdx(newFileIdToIdx);
  };

  const listProjectFiles = () => {
    setLoadingFiles(true);
    decoratedFetch(`/list_project_files/${project_id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        let newFiles = [];
        data.forEach((file, i) => {
          newFiles.push(file);
        });
        setFiles(newFiles);
        initializeFileIdToIdx(newFiles);
        setLoadingFiles(false);
      })
      .catch((error) => {
        setLoadingFiles(false);
        console.error(`Error listing files: ${error}`);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    listProjectFiles();
  }, [value]);

  const onUploadSuccess = () => {
    listProjectFiles();
  };

  const handleRemoveFiles = () => {
    setRemoveFilesLoading(true);
    decoratedFetch(`/delete_project_files/${project_id}`, {
      method: "POST",
      body: JSON.stringify({
        files: selectedFiles.map((file_id) => ({
          id: file_id,
          s3_key: files[fileIdToIdx[file_id]].s3_key,
        })),
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        const newFiles = files.filter(
          (file) => !selectedFiles.includes(file.id),
        );
        setFiles(newFiles);
        initializeFileIdToIdx(newFiles);

        setSnackbarOpen(true);
        setSnackbarMessage(
          `Deleted file${selectedFiles.length > 1 ? "s" : ""}`,
        );
        setSelectedFiles([]);
        setRemoveFilesLoading(false);
      })
      .catch((error) => {
        console.error(`Error deleting files: ${error}`);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `An error occurred when deleting file${selectedFiles.length > 1 ? "s" : ""}`,
        );
        setRemoveFilesLoading(false);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handlePreviewClose = () => {
    setPreviewFile(null);
    setPreviewFileLink("");
  };

  const previewPrevFile = () => {
    let prevIdx = fileIdToIdx[previewFile.id] - 1;
    if (prevIdx < 0) {
      prevIdx = files.length - 1;
    }
    handleFilePreview(files[prevIdx]);
  };

  const previewNextFile = () => {
    let nextIdx = fileIdToIdx[previewFile.id] + 1;
    if (nextIdx >= files.length) {
      nextIdx = 0;
    }
    handleFilePreview(files[nextIdx]);
  };

  return (
    <>
      {!previewFileLink ? null : (
        <FilePreview
          handleClose={handlePreviewClose}
          url={previewFileLink}
          file={previewFile}
          previewPrevFile={files.length > 1 ? previewPrevFile : null}
          previewNextFile={files.length > 1 ? previewNextFile : null}
        />
      )}
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div style={{ width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="right"
          sx={{ marginBottom: "10px" }}
        >
          <Grid
            item
            sx={{
              marginRight: "4px",
              marginTop: "4px",
              visibility: selectedFiles.length ? "visible" : "hidden",
            }}
          >
            {removeFilesLoading ? (
              <CircularProgress
                size="25px"
                sx={{ marginTop: "7px", marginRight: "8px" }}
              />
            ) : (
              <ThemedIconButton aria-label="remove-file" onClick={handleRemoveFiles}>
                <RemoveIcon />
              </ThemedIconButton>
            )}
          </Grid>
          <Grid item>
            <FileUpload onSuccess={onUploadSuccess} />
          </Grid>
        </Grid>
        <Paper elevation={0} sx={{ height: "500px", width: "100%" }}>
          <ThemedDataGrid
            key={gridKey}
            rows={files.map((file) => ({
              id: file.id,
              name: file.name,
              type: file.type,
              created_date: formatDate(file.created_at),
              s3_key: file.s3_key,
            }))}
            columns={columns}
            pageSize={5}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectedFiles(newSelectionModel);
            }}
            onCellClick={(params, e) => e.stopPropagation()}
            getRowId={(row) => row.id}
            selectionModel={selectedFiles}
            checkboxSelection
            loading={loadingFiles}
            localeText={{ noRowsLabel: "No files" }}
          />
        </Paper>
      </div>
    </>
  );
}

export default FileTab;
