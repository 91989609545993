import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_DISABLED_TEXT_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_DISABLED_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedTextField = ({ id, rows, placeholder, onBlur, InputProps, InputLabelProps, size, onKeyDown, inputRef, variant, label, name, value, onChange, fullWidth, disabled, style, multiline, sx }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <TextField
      sx={{
        ...sx,
        '& input, & textarea': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
          color: disabled ? (darkThemeEnabled ? DARK_THEME_DISABLED_TEXT_COLOR : LIGHT_THEME_DISABLED_TEXT_COLOR) : (darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR),
        },
        '& label': {
          color: disabled ? (darkThemeEnabled ? DARK_THEME_DISABLED_TEXT_COLOR : LIGHT_THEME_DISABLED_TEXT_COLOR) : (darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR),
        },
				"& .MuiOutlinedInput-root": {
					"& fieldset": {
						...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR }),
					},
        },
        '& .MuiInputBase-root': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiInputAdornment-root': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        ...(disabled && { 'pointerEvents': 'none' }),
      }}
      style={style}
      placeholder={placeholder}
      fullWidth={fullWidth}
      size={size}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      variant={variant}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        ...InputProps,
        readOnly: disabled,
      }}
      InputLabelProps={InputLabelProps}
      onBlur={onBlur}
      rows={rows}
      multiline={multiline}
    />
  );
};

export default ThemedTextField;
