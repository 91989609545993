import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  CircularProgress,
  Grid,
  Box,
  Slide,
  Snackbar,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../../req_utils";
import { DARK_THEME_BODY_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR, GENESIS_LOGO_COLOR, SUPPORT_EMAIL } from "../../constants";
import ContactsTab from "../ContactsTab";
import ClientInformationTabs from "../ClientInformationTabs";
import ThemedTextField from "../ThemedTextField";
import ThemedButton from "../ThemedButton";
import ThemedSelect from "../ThemedSelect";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditAccountDialog({
  isOpen,
  handleClose,
  triggerParentUpdate,
  account,
}) {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  const [accountName, setAccountName] = useState(account?.name || "");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [accountType, setAccountType] = useState(account?.account_type?.id || "");
  const [accountTypes, setAccountTypes] = useState([]);
  const [notes, setNotes] = useState("");

  const handleNotesChange = (event) => {
    setIsDirty(true);
    setNotes(event.target.value);
  }

  const handleAccountTypeChange = (event) => {
    setIsDirty(true);
    setAccountType(event.target.value);
  }

  const handleAccountNameChange = (event) => {
    setIsDirty(true);
    setAccountName(event.target.value);
  };

  useEffect(() => {
    setAccountName(account?.name || "");
    setAccountType(account?.account_type?.id || "");
    setNotes(account?.notes || "");
  }, [account]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    decoratedFetch("/list_account_types")
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' should be an array of contact types, check if it's actually an array
        if (Array.isArray(data)) {
          setAccountTypes(data);
        } else {
          console.error("Expected an array of contact types, received:", data);
          setAccountTypes([]); // Set to an empty array if the response is not as expected
        }
      })
      .catch((error) => {
        console.error("Error fetching contact types:", error);
        setAccountTypes([]); // Set to an empty array in case of an error
      });
  }, [isOpen]);

  const handleSend = () => {
    setLoading(true);
    if (!accountName || !accountType) {
      setSeverity("error");
      setAlertMessage("Required fields not filled in.");
      setLoading(false);
      setTimeout(() => setAlertMessage(""), 2500);
      return;
    }

    const payload = {
      name: accountName,
      account_type_id: accountType,
      notes: notes,
    };

    decoratedFetch(`/update_account/${account.id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            setAlertMessage("Required fields not filled in.");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setIsDirty(false);
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Account saved");
        triggerParentUpdate({
          id: account.id,
          name: accountName,
          account_type: { id: accountType },
          notes: notes,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setLoading(false);
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="edit-account-dialog-title"
      >
        <DialogTitle
          id="edit-account-dialog-title"
          sx={{ backgroundColor: darkThemeEnabled ? DARK_THEME_BODY_COLOR : "#f7f7f7" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div style={{ color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR }}>Edit Account Details</div>
            <div>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: darkThemeEnabled ? DARK_THEME_BODY_COLOR : "#f7f7f7" }}>
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              pt: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ThemedTextField
                  label="Account Name"
                  fullWidth
                  variant="outlined"
                  value={accountName}
                  onChange={handleAccountNameChange}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={6}>
                <ThemedSelect
                  labelId="account-type-label"
                  id="Account Type"
                  transparentLabel={true}
                  value={accountType}
                  renderValue={accountTypeId => accountTypeId ? accountTypes.find(accountType => accountType?.id === accountTypeId)?.name : ""}
                  label="Account Type"
                  onChange={handleAccountTypeChange}
                  required
                  sx={{ backgroundColor: "white" }}
                >
                  {accountTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </ThemedSelect>
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  label="Notes"
                  fullWidth
                  variant="outlined"
                  value={notes}
                  onChange={handleNotesChange}
                  sx={{ backgroundColor: "white" }}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: 0,
                  height: "36px",
                }}
              >
                <CircularProgress size="30px" sx={{ mr: "19px", mt: "4px" }} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: 0,
                  height: "36px",
                }}
              >
                <ThemedButton
                  variant="contained"
                  onClick={handleSend}
                  disabled={!isDirty}
                >
                  Save
                </ThemedButton>
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid sx={{ backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR, pt: '0px !important', pl: '0px !important', ml: '16px', mt: '16px' }} id="here" item xs={12}>
                <ClientInformationTabs
                  account_id={account?.id}
                  includeAccountNotesTab={false}
                  canDelete={true}
                  border={true}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
